module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"kottory.me","short_name":"kottory-me","start_url":"/","display":"standalone","background_color":"#EDF2F7","theme_color":"#319795","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a84b8068e39d0ccfa67d8422d6308a9a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null],"rehypePlugins":[null],"gatsbyRemarkPlugins":["gatsby-remark-katex",{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false,"elements":["h2","h3"]}},{"resolve":"gatsby-remark-vscode","options":{"theme":{"default":"Default Light+"}}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":[],"destinationDir":"static/"}}],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../var/task/node_modules/@vercel/gatsby-plugin-vercel-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
